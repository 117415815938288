/** @format */

import React, { useLayoutEffect, useState } from "react";
import { Button } from "primereact/button";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { GetFormById } from "../../services/Api/formApi";
import { Table } from "antd";
import { Card } from "primereact/card";
import moment from "moment";

const ViewForm = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [userData, setUserData] = useState([]);
	const [loading, setLoading] = useState(true);

	// Fetch user details by ID
	useLayoutEffect(() => {
		setLoading(true);
		GetFormById(id)
			.then((res) => {
				setUserData([res.data.data]);
				setLoading(false);
			})
			.catch((err) => {
				console.error("Error fetching form details:", err);
				setLoading(false);
			});
	}, [id]);

	// Table columns for user details
	const columns = [
		{ title: "S.No.", render: (text, record, index) => index + 1 },
		{ title: "Name", dataIndex: "name" },
		{ title: "Phone (Cell)", dataIndex: "phone_contact_cell" },
		{ title: "Phone (Home)", dataIndex: "phone_contact_home" },
		{ title: "Phone (Other)", dataIndex: "phone_contact_other" },
		{ title: "Email Address", dataIndex: "email_address" },
		{ title: "Origin Address", dataIndex: "origin_address" },
		{ title: "Extra Origin Address", dataIndex: "extra_origin_address" },
		{ title: "Storage Pickup", dataIndex: "storage_pickup" },
		{ title: "Delivery Address", dataIndex: "delivery_address" },
		{ title: "Extra Delivery Address", dataIndex: "extra_delivery_address" },
		{ title: "Storage Delivery", dataIndex: "storage_delivery" },
		{
			title: "Tractor Trailer Parking at Origin",
			dataIndex: "tractor_trailer_parking_at_origin",
		},
		{
			title: "Tractor Trailer Parking at Delivery",
			dataIndex: "tractor_trailer_parking_at_delivery",
		},
		{
			title: "Gated Community Name (Origin)",
			dataIndex: "gated_community_name_code_at_origin",
		},
		{
			title: "Gated Community Name (Destination)",
			dataIndex: "gated_community_name_code_at_destination",
		},
		{
			title: "Elevator at Origin Available",
			dataIndex: "elevator_origin_available",
		},
		{
			title: "Elevator at Destination Available",
			dataIndex: "elevator_destination_available",
		},
		{
			title: "Stairs at Origin Available",
			dataIndex: "stairs_origin_available",
		},
		{
			title: "Stairs at Destination Available",
			dataIndex: "stairs_destination_available",
		},
		{ title: "International Shipment", dataIndex: "international_shipment" },
		{
			title: "Date",
			dataIndex: "date",
			render: (date) => moment(date).format("YYYY-MM-DD"),
		},
		// { title: "Special Note", dataIndex: "special_note" },
	];

	const navigateToUser = () => {
		navigate("/forms");
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">FORM MANAGEMENT</h3>
					<p className="page-sub-title">View Form Details</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<div>
						<Button
							icon="pi pi-arrow-left"
							severity="secondary"
							onClick={navigateToUser}
							style={{ borderRadius: "5px", height: "47px" }}
						>
							<span style={{ marginLeft: "5px" }}>Return to Forms</span>
						</Button>
					</div>
					<div>
						<Button
							icon="pi pi-cloud-download"
							severity="info"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
							}}
							// onClick={exportToPDF}
						/>
					</div>
				</Box>
			</Box>
			<Box>
				<Table
					columns={columns}
					dataSource={userData}
					rowKey="id"
					pagination={false}
					loading={loading}
					scroll={{ x: "max-content" }}
				/>
			</Box>

			<Card style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}>
				<h5 style={{ color: userData[0]?.special_note ? "black" : "gray" }}>
					Special Note:
				</h5>
				{userData[0]?.special_note ? (
					<p>{userData[0].special_note}</p>
				) : (
					<p>No special note provided.</p>
				)}
			</Card>
		</Box>
	);
};

export default ViewForm;
