/** @format */

import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { GetGuestBooking } from "../../../services/Api/BookingApi";
import "./GuestBooking.css";
import { Box } from "@mui/material";
import { Button } from "primereact/button";

const GuestBookings = () => {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(50);
	const [bookingData, setBookingData] = useState([]);
	const [loading, setLoading] = useState(false);

	// Get all guest booking data
	const getData = async () => {
		try {
			setLoading(true);
			const result = await GetGuestBooking(localStorage.getItem("adminToken"));
			setBookingData(result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	// Navigate to view and edit booking pages
	const navigateToViewBooking = (id) => {
		navigate(`/viewGuestBooking/${id}`);
	};

	const navigateToEditBooking = (id) => {
		navigate(`/editGuestBooking/${id}`);
	};

	// Define table columns
	const columns = [
		{
			title: "Sr. No",
			dataIndex: "srNo",
			key: "srNo",
			render: (text, record, index) => index + 1, // Serial number starts at 1
			width: "5%",
		},
		{
			title: "Booking Id",
			dataIndex: "booking_id",
			key: "booking_id",
			width: "10%",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: "10%",
			render: (_, record) => record.name || "N/A",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			width: "10%",
			render: (text) => text.split("T")[0], // Split the date string and display only the date part
		},
		{
			title: "Booking Status",
			dataIndex: "booking_status",
			key: "booking_status",
			width: "10%",
			render: (booking_status) => {
				let color = "";
				let statusText = "";
				switch (booking_status) {
					case "PENDING":
						color = "gold";
						statusText = "Pending";
						break;
					case "SUCCESS":
						color = "green";
						statusText = "Completed";
						break;
					case "ACCEPTED":
						color = "blue";
						statusText = "Accepted";
						break;
					case "REJECTED":
						color = "red";
						statusText = "Rejected";
						break;
					case "ONGOING":
						color = "purple";
						statusText = "Ongoing";
						break;
					default:
						color = "default";
						statusText = "Unknown";
				}
				return <Tag color={color}>{statusText}</Tag>;
			},
		},
		{
			title: "Actions",
			key: "action",
			width: "10%",
			render: (_, record) => (
				<div>
					<Button
						icon="pi pi-info-circle"
						rounded
						outlined
						severity="warning"
						className="product"
						style={{ borderRadius: "25px" }}
						onClick={() => navigateToViewBooking(record.id)} // Pass only the id
					/>
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						className="mr-2"
						style={{ margin: "0px 10px", borderRadius: "25px" }}
						onClick={() => navigateToEditBooking(record.id)} // Pass only the id
					/>
				</div>
			),
		},
	];

	return (
		<div className="guest-bookings-container">
			<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						marginBottom="20px"
					>
						<div>
							<h3 className="page-title">GUEST BOOKINGS</h3>
							<p className="page-sub-title">View Guest's Bookings</p>
						</div>
						</Box>
			{loading ? (
				<p>Loading...</p>
			) : (
				<Table
					columns={columns}
					dataSource={bookingData}
					pagination={{
						pageSize: pageSize,
						showSizeChanger: true,
						pageSizeOptions: [50, 75, 100],
						onShowSizeChange: (current, size) => setPageSize(size),
					}}
					rowKey={(record) => record.id}
				/>
			)}
		</div>
	);
};

export default GuestBookings;
