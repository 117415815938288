/** @format */

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { GetAllOrders } from "../../../services/Api/OrderApi";
import moment from "moment";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Box } from "@mui/material";

const Orders = () => {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(50);
	const [orderData, setOrderData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [backupData, setBackupData] = useState([]);

	// Fetch all orders
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllOrders(localStorage.getItem("adminToken"));
			setOrderData(result.data.data);
			setBackupData(result.data.data);
		} catch (e) {
			console.error(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("Unauthorized access.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const navigateToViewOrder = (id) => {
		navigate(`/viewOrder/${id}`);
	};

	const navigateToEditOrder = (id) => {
		navigate(`/editOrder/${id}`);
	};

	const onSearch = (searchValue) => {
		const filteredData = backupData.filter((item) =>
			item?.order_user?.user_profile?.name
				?.toLowerCase()
				.includes(searchValue.toLowerCase())
		);

		setOrderData(filteredData);
	};

	const columns = [
		{
			title: "Sr. No",
			key: "index",
			render: (text, record, index) => index + 1,
			width: "10%",
		},
		{
			title: "Order Id",
			dataIndex: "order_id",
			key: "order_id",
			width: "15%",
		},
		{
			title: "Ordered By",
			dataIndex: ["order_user", "user_profile", "name"],
			key: "name",
			width: "20%",
		},
		{
			title: "Date",
			dataIndex: "created_at",
			key: "created_at",
			width: "20%",
			render: (created_at) => moment(created_at).format("DD/MM/YYYY hh:mm:ss"),
		},
		{
			title: "Order Status",
			dataIndex: "order_status",
			key: "order_status",
			width: "15%",
			render: (orderStatus) => {
				let statusText, statusColor;

				switch (orderStatus) {
					case "BOOKED":
						statusText = "Booked";
						statusColor = "#f4bd0e";
						break;
					case "ACCEPTED":
						statusText = "Accepted";
						statusColor = "green";
						break;
					case "SHIPPED":
						statusText = "Shipped";
						statusColor = "blue";
						break;
					case "REJECTED":
						statusText = "Rejected";
						statusColor = "red";
						break;
					case "DELIVERED":
						statusText = "Delivered";
						statusColor = "purple";
						break;
					default:
						statusText = "Unknown";
						statusColor = "black";
				}

				return (
					<span style={{ color: statusColor, fontWeight: 500 }}>
						{statusText}
					</span>
				);
			},
		},
		{
			title: "Actions",
			key: "actions",
			width: "20%",
			render: (record) => (
				<div>
					<Button
						icon="pi pi-info-circle"
						rounded
						outlined
						severity="warning"
						className="product"
						style={{ borderRadius: "25px" }}
						onClick={() => navigateToViewOrder(record.id)}
					></Button>
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						className="mr-2"
						style={{ margin: "0px 10px", borderRadius: "25px" }}
						onClick={() => navigateToEditOrder(record.id)}
					></Button>
				</div>
			),
		},
	];

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">ORDER MANAGEMENT</h3>
					<p className="page-sub-title">View All Orders</p>
				</div>

				<Box>
					<span className="p-input-icon-left">
						<i className="pi pi-search" />
						<InputText
							type="search"
							onChange={(e) => onSearch(e.target.value)}
							placeholder="Search..."
						/>
					</span>
				</Box>
			</Box>
			{loading ? (
				<p>Loading...</p>
			) : (
				<Table
					columns={columns}
					dataSource={orderData}
					rowKey="id"
					pagination={{
						pageSize: pageSize,
						showSizeChanger: true,
						pageSizeOptions: [50, 75, 100],
						onChange: (page, size) => setPageSize(size),
					}}
				/>
			)}
		</Box>
	);
};

export default Orders;
