/** @format */

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Box } from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import { GetAllForm } from "../../services/Api/formApi";

const Form = () => {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(50);
	const [quoteData, setQuoteData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(false);

	// Get all quotes
	const getData = async () => {
		try {
			setLoading(true);
			const result = await GetAllForm(localStorage.getItem("adminToken"));
			setQuoteData(result.data.data);
			setFilteredData(result.data.data);
			console.log("Quotes Data: ", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const onSearch = (searchField) => {
		const searchTerm = searchField.toLowerCase();
		const filteredList = quoteData.filter((item) => {
			// Match with name, message, or service name
			return item.name.toLowerCase().includes(searchTerm);
		});
		setFilteredData(filteredList); // Set the filtered data to state
	};

	const columns = [
		{
			title: "Sr. No", // Serial number column
			dataIndex: "srNo",
			key: "srNo",
			render: (text, record, index) => index + 1, // Render serial numbers starting from 1
			width: "10%",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: "20%",
		},
		{
			title: "Email",
			dataIndex: "email_address",
			key: "email",
			width: "30%",
		},
		{
			title: "Filled At",
			dataIndex: "created_at",
			key: "created_at",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
			width: "30%",
		},
		{
			title: "Actions",
			dataIndex: "action",
			width: "40%",
			render: (text, record) => (
				<div>
					<Button
						icon="pi pi-eye"
						rounded
						outlined
						className="mr-2"
						style={{ marginRight: "10px", borderRadius: "25px" }}
						onClick={() => navigateToViewUser(record.id)}
					/>
				</div>
			),
		},
	];

	const navigateToViewUser = (id) => {
		navigate(`/viewForm/${id}`);
	};

	return (
		<div>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<div>
					<h3 className="page-title">FORM Management</h3>
					<p className="page-sub-title">View all Forms</p>
				</div>
				<Box>
					<span className="p-input-icon-left">
						<i className="pi pi-search" />
						<InputText
							type="search"
							onChange={(e) => {
								onSearch(e.target.value);
							}}
							placeholder="Search..."
						/>
					</span>
				</Box>
			</Box>
			{loading ? (
				<p>Loading...</p>
			) : (
				<Table
					columns={columns}
					dataSource={filteredData}
					pagination={{
						pageSize: pageSize,
						showSizeChanger: true,
						pageSizeOptions: [50, 75, 100],
						onShowSizeChange: (current, size) => setPageSize(size),
					}}
					rowKey={(record) => record.id} // Use the `id` as a unique key for each row
				/>
			)}
		</div>
	);
};

export default Form;
