/** @format */
import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Box } from "@mui/material";
import { Table, Tag } from "antd";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import "./Bookings.css";
import { InputText } from "primereact/inputtext";
import { GetUserBooking } from "../../../services/Api/BookingApi";

const Bookings = () => {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(50);
	const [bookingData, setBookingData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState("all");


	const onChange = (key) => {
		setActiveTab(key);
		getData(key);
	};

	// Fetch all booking data
	const getData = async (booking_status) => {
		try {
			setLoading(true);
			let result = await GetUserBooking(
				localStorage.getItem("adminToken"),
				booking_status
			);
			setBookingData(result.data.data);
			console.log("userbooking==>", result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData("all");
	}, []);

	const navigateToViewBooking = (event, id) => {
		navigate(`/viewBooking/${id}`);
	};

	const navigateToEditBooking = (event, id) => {
		navigate(`/editBooking/${id}`);
	};



	const columns = [
		{
			title: "Sr. No",
			key: "serialNumber",
			width: "5%",
			render: (text, record, index) => index + 1,
		},
		{
			title: "Booking Id",
			dataIndex: "booking_id",
			key: "booking_id",
			width: "10%",
		},
		{
			title: "Booked By",
			dataIndex: ["booking_user", "user_profile", "name"],
			key: "name",
			width: "10%",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			width: "10%",
			render: (text) => text.split("T")[0], // Split the date string and display only the date part
		},
		{
			title: "Booking Status",
			dataIndex: "booking_status",
			key: "booking_status",
			width: "10%",
			render: (booking_status) => {
				let color = "";
				let statusText = "";
				switch (booking_status) {
					case "PENDING":
						color = "gold";
						statusText = "Pending";
						break;
					case "SUCCESS":
						color = "green";
						statusText = "Completed";
						break;
					case "ACCEPTED":
						color = "blue";
						statusText = "Accepted";
						break;
					case "REJECTED":
						color = "red";
						statusText = "Rejected";
						break;
					case "ONGOING":
						color = "purple";
						statusText = "Ongoing";
						break;
					default:
						color = "default";
						statusText = "Unknown";
				}
				return <Tag color={color}>{statusText}</Tag>;
			},
		},
		{
			title: "Actions",
			key: "action",
			width: "10%",
			render: (text, record) => (
				<div>
					<Button
						icon="pi pi-info-circle"
						rounded
						outlined
						severity="warning"
						className="product"
						style={{ borderRadius: "25px" }}
						onClick={(event) => navigateToViewBooking(event, record.id)}
					/>
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						className="mr-2"
						style={{ margin: "0px 10px", borderRadius: "25px" }}
						onClick={(event) => navigateToEditBooking(event, record.id)}
					/>
				</div>
			),
		},
	];

	const tabsContent = [
		{ label: "All", key: "all" },
		{ label: "Ongoing", key: "ONGOING" },
		{ label: "Completed", key: "COMPLETED" },
	];

	return (
		<Box>
			{loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
				<>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						marginBottom="20px"
					>
						<div>
							<h3 className="page-title">USER BOOKINGS</h3>
							<p className="page-sub-title">View User's Bookings</p>
						</div>
						<div>
							<Tabs
								defaultActiveKey="all"
								onSelect={onChange}
								activeKey={activeTab}
							>
								{tabsContent.map((tab) => (
									<Tab eventKey={tab.key} title={tab.label} key={tab.key}>
										{tab.children}
									</Tab>
								))}
							</Tabs>
						</div>
					</Box>

					<Table
						columns={columns}
						dataSource={bookingData}
						pagination={{
							pageSize: pageSize,
							showSizeChanger: true,
							pageSizeOptions: [50, 75, 100],
							onChange: (page, newPageSize) => {
								setPageSize(newPageSize);
							},
						}}
						rowKey={(record) => record.id}
						scroll={{ y: 400 }}
					/>
				</>
			)}
		</Box>
	);
};

export default Bookings;
