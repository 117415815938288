/** @format */

import React, { useEffect, useState } from "react";
import { Table, message } from "antd";
import { Box } from "@mui/material";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { GetAllBlogs, DeleteBlog } from "../../services/Api/BlogsApi";
import Alert from "../../pages/Customer/Alert";

const Blogs = () => {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(50);
	const [blogData, setBlogData] = useState([]);
	const [loading, setLoading] = useState(false);

	// Fetch all blogs
	const getData = async () => {
		try {
			setLoading(true);
			let result = await GetAllBlogs(localStorage.getItem("adminToken"));
			setBlogData(result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("Unauthorized access.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	// Handle delete blog
	const handleDelete = (id) => {
		DeleteBlog(id)
			.then((res) => {
				message.success(res?.data?.message);
				getData(); // Refresh blog data
			})
			.catch((error) => {
				console.log(error);
			});
	};

	// Navigate to add/edit blog
	const navigateToAddBlog = () => {
		navigate("/addBlog");
	};
	const navigateToEditBlog = (id) => {
		navigate(`/editBlog/${id}`);
	};

	// Ant Design Table columns
	const columns = [
		{
			title: "Sr. No.",
			dataIndex: "index",
			key: "index",
			render: (text, record, index) => index + 1, // Generating sequential numbers
			width: "10%",
		},

		{
			title: "Heading",
			dataIndex: "heading",
			key: "heading",
			width: "45%",
		},
		{
			title: "Actions",
			key: "actions",
			width: "30%",
			render: (record) => (
				<div>
					<Button
						icon="pi pi-pencil"
						rounded
						outlined
						className="mr-2"
						style={{ marginRight: "10px", borderRadius: "25px" }}
						onClick={() => navigateToEditBlog(record.id)}
					/>
					<Alert title="Blog" handleDelete={() => handleDelete(record.id)} />
				</div>
			),
		},
	];

	return (
		<Box >
			{loading ? (
				<p style={{ fontSize: "16px" }}>Loading...</p>
			) : (
				<>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
            marginBottom="20px"
					>
						<div>
            <h3 className="page-title">Blog MANAGEMENT</h3>
            <p className="page-sub-title">View all Blogs</p>
            </div>
						<Box>
							<Button
								label="Add Blog"
								icon="pi pi-plus"
								severity="info"
								onClick={navigateToAddBlog}
								style={{
									margin: "0px 0px",
									borderRadius: "5px",
									height: "40px",
								}}
							/>
						</Box>
					</Box>

					<div style={{ width: "100%", marginTop: "20px" }}>
						<Table
							columns={columns}
							dataSource={blogData}
							rowKey="id"
							pagination={{
								pageSize: pageSize,
								showSizeChanger: true,
								pageSizeOptions: [50, 75, 100],
								onChange: (page, size) => setPageSize(size),
							}}
						/>
					</div>
				</>
			)}
		</Box>
	);
};

export default Blogs;
