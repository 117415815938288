/** @format */

import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table, Modal, message } from "antd";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { GetUsers, DeleteUser } from "../../services/Api/Api";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import moment from "moment/moment";

const Customers = () => {
	const navigate = useNavigate();
	const [customerData, setCustomerData] = useState([]);
	const [driverData, setDriverData] = useState([]);
	const [backupData, setBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [tabValue, setTabValue] = useState(0); // 0 for Customers, 1 for Drivers
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
		setSelectedRowKeys([]); // Clear any selected rows when switching tabs
	};

	useEffect(() => {
		fetchData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tabValue, tableParams]);

	const fetchData = async (params = {}) => {
		setLoading(true);
		try {
			const roleId = tabValue === 0 ? 6 : 7; // 6 for Customers, 7 for Drivers
			let result = await GetUsers(localStorage.getItem("adminToken"), roleId);
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1, // Adding index for serial number
			}));

			if (tabValue === 0) {
				setCustomerData(newData);
			} else {
				setDriverData(newData);
			}

			setBackupData(newData);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = (userIds) => {
		Modal.confirm({
			title: "Confirm",
			content: `Are you sure you want to delete ${
				userIds.length > 1 ? "these users" : "this user"
			}?`,
			onOk: async () => {
				try {
					await DeleteUser(userIds, localStorage.getItem("adminToken"));
					message.success("User(s) deleted successfully");
					fetchData();
				} catch (error) {
					console.error("Error deleting user(s):", error);
					message.error("Error deleting user(s)");
				}
			},
		});
	};

	const navigateToAddUser = () => {
		navigate("/addCustomer");
	};

	const navigateToViewUser = (id) => {
		navigate(`/viewCustomer/${id}`);
	};

	const onSearch = (searchValue) => {
		const filteredData = backupData.filter(
			(item) =>
				item?.user_profile?.name
					?.toLowerCase()
					.includes(searchValue.toLowerCase()) ||
				item?.email?.toLowerCase().includes(searchValue.toLowerCase())
		);

		if (tabValue === 0) {
			setCustomerData(filteredData);
		} else {
			setDriverData(filteredData);
		}
	};

	const columns = [
		{
			title: "Sr.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
		},
		{
			title: "Name",
			dataIndex: ["user_profile", "name"],
			sorter: (a, b) => a.user_profile.name.localeCompare(b.user_profile.name),
			width: "20%",
		},
		{
			title: "Email",
			dataIndex: "email",
			width: "20%",
		},
		{
			title: "Mobile",
			dataIndex: ["user_profile", "mobile"],
			render: (mobile) => (mobile ? mobile : "---"),
			width: "20%",
		},
		{
			title: "Account Created On",
			dataIndex: "created_at",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
			width: "20%",
		},
		{
			title: "Actions",
			dataIndex: "action",
			width: "50%",
			render: (text, record) => (
				<div>
					<Button
						icon="pi pi-eye"
						rounded
						outlined
						className="mr-2"
						style={{ marginRight: "10px", borderRadius: "25px" }}
						onClick={() => navigateToViewUser(record.id)}
					/>
					<Button
						icon="pi pi-trash"
						rounded
						outlined
						severity="danger"
						style={{ borderRadius: "25px", color: "red", borderColor: "red" }}
						onClick={() => handleDelete([record.id])}
					/>
				</div>
			),
		},
	];

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	return (
		<Box m="20px">
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				style={{
					border: "1px solid #D2D3D4",
					borderRadius: "10px",
					marginBottom: "10px",
          backgroundColor:"white"
				}}
			>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					style={{ marginBottom: "10px" }}
				>
					<Tab
						icon={<PersonPinIcon />}
						iconPosition="start"
						label="Customers"
					/>
					<Tab icon={<PersonPinIcon />} iconPosition="end" label="Drivers" />
				</Tabs>

				<Box>
					<span className="p-input-icon-left">
						<i className="pi pi-search" />
						<InputText
							type="search"
							onChange={(e) => onSearch(e.target.value)}
							placeholder="Search..."
						/>
					</span>
					<Button
						icon="pi pi-trash"
						severity="danger"
						style={{
							marginLeft: "10px",
							borderRadius: "5px",
							height: "47px",
							cursor: "pointer",
						}}
						onClick={() => handleDelete(selectedRowKeys)}
						disabled={!selectedRowKeys.length}
					/>
					<Button
						label=" Add New User"
						icon="pi pi-plus"
						severity="info"
						style={{ margin: "0px 10px", borderRadius: "5px" }}
						onClick={navigateToAddUser}
					/>
				</Box>
			</Box>

			<Table
				columns={columns}
				rowKey="id"
				dataSource={tabValue === 0 ? customerData : driverData}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
				rowSelection={{
					selectedRowKeys,
					onChange: (newSelectedRowKeys) =>
						setSelectedRowKeys(newSelectedRowKeys),
				}}
			/>
		</Box>
	);
};

export default Customers;
